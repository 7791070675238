import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { init, useConnectWallet } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import ATSession from '../../helpers/ATSession';
import { getTokensList, getPendingTransaction } from '../../services/ATClaimAPIService';
import ATCBlockchainUtils from '../../helpers/ATCBlockchainUtils';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import ATCHeader from '../../UI/header/ATCHeader';
import ATCLoader from '../../UI/loader/ATCLoader';
import { ethers } from 'ethers';

const injected = injectedModule();
init({
  wallets: [injected],
  chains: [
    {
      id: process.env.REACT_APP_CHAIN_ID as string,
      token: 'rETH',
      label: process.env.REACT_APP_CHAIN_NAME as string,
      rpcUrl: process.env.REACT_APP_RPC_URL as string
    }
  ],
  appMetadata: {
    name: process.env.REACT_APP_NAME as string,
    icon: '<svg><svg/>',
    description: process.env.REACT_APP_DESC as string,
    recommendedInjectedWallets: [{ name: 'MetaMask', url: 'https://metamask.io' }]
  },
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: false
    },
    mobile: {
      position: 'topRight',
      enabled: false
    }
  }
});

const minuteSeconds = 60;
const hourSeconds = 3600;
const daySeconds = 86400;

const timerProps = {
  isPlaying: true,
  size: 120,
  strokeWidth: 6
};

const renderTime = (dimension: any, time: any) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time: any) => (minuteSeconds - time) | 0;
const getTimeMinutes = (time: any) => ((time % hourSeconds) / minuteSeconds) | 0;
const getTimeHours = (time: any) => ((time % daySeconds) / hourSeconds) | 0;
const getTimeDays = (time: any) => (time / daySeconds) | 0;

function ATClaimVC() {
  const [{ wallet }] = useConnectWallet();
  const [endDuration, setEndDuration] = useState(0);

  const [address, setAddress] = useState('');
  const [walletStatus, setWalletStatus] = useState(false);

  const [nftCount, setNftCount] = useState(0);
  const [x1Multiplier, setX1Multiplier] = useState(0);
  const [x2Multiplier, setX2Multiplier] = useState(0);
  const [x3Multiplier, setX3Multiplier] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [claimStatus, setClaimStatus] = useState(0);
  const [walletBalance, setWalletBalance] = useState('');
  const [rewardStatus, setRewardStatus] = useState(false);
  const [loaderStatus, setLoaderStatus] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    // const walletSession = ATSession.getValueFromStorage('wallet');
    // if (walletSession) {
    //   connect({
    //     autoSelect: {
    //       label: walletSession,
    //       disableModals: false
    //     }
    //   });
    // }
  }, []);

  useEffect(() => {
    if (!wallet) {
      ATSession.removeValueFromStorage('wallet');
      ATSession.removeValueFromStorage('address');
    } else {
      ATSession.setValueFromStorage('wallet', wallet.label);
      ATSession.setValueFromStorage('address', wallet.accounts[0].address);
      getUserClaimData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const handleChange = (event: any) => {
    if(event.target.value.length===0){
      setWalletStatus(false);
    }
    setAddress(event.target.value);
  };

  const checkAddress = () => {
    if (ethers.utils.isAddress(address)) {
      setWalletStatus(true);
      ATSession.setValueFromStorage('wallet', address);
      ATSession.setValueFromStorage('address', address);
      getUserClaimData();
    } else {
      toast('Invalid addres');
    }
  }

  const stratTime = Date.now() / 1000;
  const endTime = stratTime + endDuration;
  const remainingTime = (endTime - stratTime) / 1000;
  const days = Math.ceil(remainingTime / daySeconds);
  const daysDuration = days * daySeconds;

  const getUserClaimData = async () => {
    const getAddress = ATSession.getValueFromStorage('wallet');
    // let balanceResult = await ATCBlockchainUtils.getBalance(getAddress);
    setWalletBalance('0');
    getTokensList({
      address: getAddress,
      type: 0
    }).then(async (resultMain: any) => {
      console.log(resultMain);
      setEndDuration(resultMain.end);
      setNftCount(resultMain.total_nft);
      setX1Multiplier(resultMain.x1_card);
      setX2Multiplier(resultMain.x2_card);
      setX3Multiplier(resultMain.x3_card);
      setTotalRewards(resultMain.rewards);
      setRewardStatus(resultMain.claim); 
      setClaimStatus(resultMain.claim_status);
      if (resultMain.claim_status === 1) {
        checkPendingTransaction();
      }

    });
  }

  const checkPendingTransaction = () => {
    var params = {
      address: ATSession.getValueFromStorage('wallet')
    }
    getPendingTransaction(params).then(async (resultMain: any) => {
      setClaimStatus(resultMain.status ? 0 : 1)
    });
  } 

  /**
 * This is the function which used to track for token id
 */
  const getTransactionToken = async (hash: string, type: string, detail: any) => {
    const receipt = await ATCBlockchainUtils.getReceiptForHash(hash, wallet?.provider);
    setTimeout(() => {
      parseTokenResult(receipt, hash, type, detail);
    }, 3000);
  };

  /**
  * This is the function which used to track for token id on loop
  */
  const parseTokenResult = (result: any, transactionHash: string, type: string, detail: any) => {
    if (result != null) {
      setTimeout(() => {
        if (type === 'claim') {
          console.log('hash', transactionHash);
          setLoaderStatus(false);
          toast(t('Claim has been completed successfully!'));
        }
        getUserClaimData();
      }, 5000);
      return;
    }
    getTransactionToken(transactionHash, type, detail);
  };

  const logOutAction = () => {
    // if (wallet) {
    //   disconnect(wallet)
    // }
    ATSession.removeValueFromStorage('wallet');
    ATSession.removeValueFromStorage('address');
    window.location.reload();
  }

  return (
    <>
      <div>
        <ATCHeader walletStatus={walletStatus} handleChange={handleChange} address={address} checkAddress={checkAddress} walletInfo={ATSession.getValueFromStorage('wallet')} logOutAction={logOutAction} balance={walletBalance} />
        {walletStatus && address.length > 0 &&
          <p className="over_view_head_content">Snapshot Results For {address}</p>
        }
        <section className="over_view_page">
          <div className="over_grid_box">
            <div className="over_view_box_sec">
              <span className='over_view_img'>
                <img src="../../assets/images/atcc-logo.png" alt="over_view_ethereum" />
              </span>
              <p>
                Balance of NFT
              </p>
              <img src="../../assets/images/icons/header_ethereum.png" alt="header-ethereum" className="header_ethereum" />
              <span>
                {nftCount} ATCC SLOTHS
              </span>
            </div>
            <div className="over_view_box_sec">
              <span className='over_view_img'>
                <img src="../../assets/images/icons/10x.png" alt="over_view_ethereum" />
              </span>
              <p>
                Balance of multiplier
              </p>
              <img src="../../assets/images/icons/header_ethereum.png" alt="header-ethereum" className="header_ethereum" />
              <span>
                {x1Multiplier} 10x Multiplier
              </span>
            </div>
            <div className="over_view_box_sec">
              <span className='over_view_img'>
                <img src="../../assets/images/icons/20x.png" alt="over_view_ethereum" />
              </span>
              <p>
                Balance of multiplier
              </p>
              <img src="../../assets/images/icons/header_ethereum.png" alt="header-ethereum" className="header_ethereum" />
              <span>
                {x2Multiplier} 20x Multiplier
              </span>
            </div>
            <div className="over_view_box_sec">
              <span className='over_view_img'>
                <img src="../../assets/images/icons/50x.png" alt="over_view_ethereum" />
              </span>
              <p>
                Balance of multiplier
              </p>
              <img src="../../assets/images/icons/header_ethereum.png" alt="header-ethereum" className="header_ethereum" />
              <span>
                {x3Multiplier} 50x Multiplier
              </span>
            </div>
            {/* <div className="over_view_box_sec">
                  <span className='over_view_img'>
                    <img src="../../assets/images/icons/reward3.png" alt="over_view_ethereum" />
                  </span>
                  <p>
                    Reward claimed
                  </p>
                  <span>
                    {historyRewards} CADDIE
                  </span>

                </div> */}
          </div>
        </section>
        <section className="count_down_sec" style={{ display: 'none' }}>
          <div className="container">
            <div className="count_timer">
              <h3 className='count_down_time_head'>
                COUNTDOWN
              </h3>
              <div className="count_down_flex">
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#333"
                  key={endDuration + 1}
                  duration={daysDuration}
                  initialRemainingTime={remainingTime}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("days", getTimeDays(daysDuration - elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#333"
                  key={endDuration + 2}
                  duration={daySeconds}
                  initialRemainingTime={remainingTime % daySeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > hourSeconds
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("hours", getTimeHours(daySeconds - elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#333"
                  key={endDuration + 3}
                  duration={hourSeconds}
                  initialRemainingTime={remainingTime % hourSeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > minuteSeconds
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("minutes", getTimeMinutes(hourSeconds - elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
                <CountdownCircleTimer
                  {...timerProps}
                  colors="#333"
                  key={endDuration + 4}
                  duration={minuteSeconds}
                  initialRemainingTime={remainingTime % minuteSeconds}
                  onComplete={(totalElapsedTime) => ({
                    shouldRepeat: remainingTime - totalElapsedTime > 0
                  })}
                >
                  {({ elapsedTime, color }) => (
                    <span style={{ color }}>
                      {renderTime("seconds", getTimeSeconds(elapsedTime))}
                    </span>
                  )}
                </CountdownCircleTimer>
              </div>
            </div>
          </div>
        </section>
        {!rewardStatus && claimStatus === 1 &&
          <section className="amount_claimed_sec">
            <div className="container">
              <ul>
                <li>
                  <p>
                    Caddie cash Rewards
                  </p>
                  <img src="../../assets/images/icons/over_view_ethereum.png" alt="over_view_ethereum" />
                  <span>
                    {totalRewards}
                  </span>
                </li>
                <li style={{ display: 'none' }}>
                  {loaderStatus && <ATCLoader title={t('Please Wait...')} />}
                  {!loaderStatus &&
                    <Button
                      size="lg">
                      {t('Comming Soon')}
                    </Button>
                  }
                </li>
              </ul>
            </div>
          </section>
        }

      </div>
    </>
  )
}

export default ATClaimVC;
