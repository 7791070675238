import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
function ATCHeader(props: any) {
  return (
    <div className="header-container d-flex justify-content-between">
      <div className="logo">
        <img src="./assets/images/logo.png" alt="logo" />
      </div>

      <div className="guest-container">
        <div className="guest-container-inner">
          <h1>
            <Link to="/"> Check Another Wallet</Link>
          </h1>
          <input type="text" name="wallet_address" id="wallet_address" placeholder="Enter wallet address" onChange={props.handleChange} value={props.address} />
          <br />
          <Button
            variant="danger"
            size="lg"
            onClick={() => {
              props.checkAddress();
            }}>
            Submit
          </Button>
        </div>
      </div>
      
    </div>
  );
}

export default ATCHeader